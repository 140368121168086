import React from "react";
import { Box, Flex, Image, Heading } from "@chakra-ui/react";
import Companies from "../../images/partners.png";

const CompaniesSection = () => {
  return (
    <Box bg="#white" py={10} style={{ fontFamily: "Avenir, sans-serif" }}>
      <Heading
        as="h2"
        size="2xl"
        textAlign="center"
        color="#801423"
        mb={6}
        style={{ fontFamily: "Avenir, sans-serif" }}
      >
        Our Partners
      </Heading>

      <Flex justify="center" pb={10}>
        <Image
          src={Companies}
          alt="Companies Image"
          maxW="100%"
          objectFit={"cover"}
          loading="lazy"
        />
      </Flex>
    </Box>
  );
};

export default CompaniesSection;
